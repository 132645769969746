$icon-values: (
  '16': $icon-16,
  '21': $icon-21,
  '24': $icon-24,
  '32': $icon-32,
  '40': $icon-40,
  '72': $icon-72,
  '80': $icon-80,
);

.icon {
  height: 1em;
  width: 1em;
  line-height: 1;
  font-size: $icon-21;

  &.is-small {
    font-size: $icon-16;
  }

  &.is-large {
    font-size: $icon-24;
  }

  @each $name, $size in $icon-values {
    &.is-icon-#{$name} {
      font-size: $size;
    }
  }
}

.icon-text {
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  line-height: 1;

  &.is-top {
    align-items: flex-start;
  }

  .icon {
    margin: 0 !important;
  }
}

.icon-text-list {
  li:not(:last-child) {
    margin-bottom: $space-8;
  }
}
