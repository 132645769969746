.modal {
  // isolation: isolate;

  .modal-content {
    background: $light-100;
    display: flex;
    flex-direction: column;
    max-width: $modal-content-width;

    @include tablet {
      max-height: 90vh;
    }

    header {
      padding: $space-8;
      display: flex;
    }

    main {
      flex: 1;
      overflow: auto;
      padding: 0;

      hr {
        background: $light-250;
      }

      .button.is-menu-item {
        justify-content: start;
      }

      @include desktop {
        padding: 0 $space-8;
      }
    }

    footer {
      padding: 0 $space-40;
    }
  }

  &.is-right {
    .modal-content {
      max-height: none;
      max-width: 480px;
      overflow: hidden;
      margin-right: 0;
      margin-left: auto;
      height: 100vh;
    }
  }

  &.is-left {
    .modal-content {
      overflow: hidden;
      margin-left: 0;
      margin-right: auto;
      height: 100vh;

      @include tablet {
      }
    }
  }

  &.is-dialog {
    .modal-content {
      max-width: 480px;
    }
  }

  &[data-id='modal-search'] {
    @include tablet {
      justify-content: flex-start;

      .modal-content {
        max-height: 100vh;
        margin-top: 80px;
        margin-bottom: 80px;
      }
    }

    main {
      > section {
        &:not(:last-child) {
          margin-bottom: $size-24;
        }

        .button.is-large {
          justify-content: start;
          padding: $space-8;
          border-radius: 0;
          border-bottom: 1px solid $border;
          font-weight: $weight-normal;
          gap: $space-16;

          .is-year {
            font-weight: $weight-semibold;
            width: 50px;
          }

          &:hover {
            background: $white;
            color: $primary;
          }
        }
      }
    }
  }
}
