form {
  // font-size: $size-18;

  .field {
    &:not(:last-child) {
      margin-bottom: $space-16;
    }

    .p-inputtext {
      width: 100%;
    }
  }

  .label {
    font-size: $size-18;
  }
}

.textarea {
  &.is-borderless {
    padding: $space-8 0;
    border: 0;
    border-radius: 0;
    box-shadow: none !important;
  }
}

button.input {
  cursor: pointer;
}

.control.is-toggle {
  display: flex;
  align-items: center;
  padding: 0 8px;
  border: 1px solid $border;
  border-radius: 0 $radius-40 $radius-40 0;
}
