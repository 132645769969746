:root {
  --p-primary-50: #{$light-100} !important;
  --p-primary-100: #{$light-100} !important;
  --p-primary-200: #{$light-200} !important;
  --p-primary-300: #{$light-300} !important;
  --p-primary-400: #{$orange-400} !important;
  --p-primary-500: #{$orange-700} !important;
  --p-primary-600: #{$orange-700} !important;
  --p-primary-700: #{$orange-700} !important;
  --p-primary-800: #{$orange-700} !important;
  --p-primary-900: #{$orange-700} !important;
  --p-primary-950: #{$orange-700} !important;

  --p-red-50: #{$pink-100} !important;
  --p-red-100: #{$pink-100} !important;
  --p-red-200: #{$pink-100} !important;
  --p-red-300: #{$pink-400} !important;
  --p-red-400: #{$pink-400} !important;
  --p-red-500: #{$pink-400} !important;
  --p-red-600: #{$pink-400} !important;
  --p-red-700: #{$pink-700} !important;
  --p-red-800: #{$pink-700} !important;
  --p-red-900: #{$pink-700} !important;
  --p-red-950: #{$pink-700} !important;

  --p-green-50: #{$green-100} !important;
  --p-green-100: #{$green-100} !important;
  --p-green-200: #{$green-100} !important;
  --p-green-300: #{$green-400} !important;
  --p-green-400: #{$green-400} !important;
  --p-green-500: #{$green-400} !important;
  --p-green-600: #{$green-400} !important;
  --p-green-700: #{$green-700} !important;
  --p-green-800: #{$green-700} !important;
  --p-green-900: #{$green-700} !important;
  --p-green-950: #{$green-700} !important;

  --p-form-field-focus-border-color: $black !important;
}

.p-datatable {
  .p-datatable-tbody > tr > td,
  .p-datatable-thead > tr > th {
    @include mobile {
      padding: $space-8;
    }

    @include tablet {
      font-size: $size-18;
    }
  }

  tr.p-datatable-row-expansion {
    > td {
      padding: 0 0 0 4px;

      .p-datatable {
        tr:last-child > td {
          border-bottom: 0;
        }
      }
    }
  }
}

.p-select,
.p-multiselect {
  padding: 0;
}

.p-multiselect-overlay,
.p-select-overlay {
  max-width: 100%;

  .p-select-option,
  .p-multiselect-option {
    white-space: wrap;
  }
}

.p-toast {
  @include mobile {
    top: 0 !important;

    .p-toast-message {
      border-radius: 0;
      border-width: 0 0 1px;
    }
  }

  max-width: 100%;
}
