@use 'sass:map';

.button {
  font-weight: $weight-semibold;
  font-size: $size-18;
  padding: 0.5rem 1rem;
  line-height: 1;
  gap: 8px;
  z-index: 0;
  position: relative;
  height: clamp(2rem, 1.8rem + 1vw, 3rem); // 32-48px

  &,
  .tag {
    transition: background-color 0.25s, color 0.5s, border 0.25s;
  }

  .icon {
    width: 1em;
    height: 1em;
    flex-shrink: 0;

    &:first-child:last-child,
    &:first-child:not(:last-child),
    &:last-child:not(:first-child) {
      margin: 0;
    }
  }

  &.has-border {
    border: 1px solid $border;
  }

  &.is-small {
    height: 32px;
    font-size: $size-18;

    &:not(.is-rounded) {
      border-radius: $radius-24;
    }
  }

  &.is-medium {
    height: clamp(1.75rem, 1.6rem + 0.75vw, 2.5rem); // 28-40
    border-radius: $radius-40;
    font-size: $size-18;
  }

  &.is-large {
    height: clamp(2rem, 1.7rem + 1.5vw, 3.5rem); // 32-56
    border-radius: $radius-56;
    padding: $space-16 $space-32;
    font-size: $size-18;
  }

  &.is-1by1 {
    padding: $space-8;
  }

  &.is-transparent {
    background: transparent;
    border-color: transparent;
  }

  &.is-primary {
    .icon {
      color: $white;
    }

    &:not(.no-hover-effect) {
      &::after,
      &::before {
        content: '';
        position: absolute;
        background-color: inherit;
        border-radius: inherit;
        top: -1px;
        right: 0;
        width: 72px;
        height: calc(100% + 2px);
        transition: transform 0.3s, opacity 0.3s;
        z-index: -1;
      }
    }

    &:hover:not([disabled]) {
      background: $orange-700;

      &::after {
        transform: translateX(16px);
        opacity: 0.7;
      }

      &::before {
        transform: translateX(30px);
        opacity: 0.4;
      }
    }
  }

  &.is-menu-item {
    text-transform: uppercase;

    &:not([disabled]):hover {
      background: $light-250;
      color: $orange-400;
    }

    &.is-active {
      background: $light-250;
      border: 0;
      border-bottom: 2px solid $orange-400;
    }

    &.is-1by1 {
      border-radius: $radius-8;
    }

    > .icon {
      font-size: $icon-32;
    }
  }

  &.is-secondary {
    border: 1px solid $border;

    .tag {
      background: $light-250;
    }

    .icon {
      color: $orange-400;
    }

    &:hover,
    &.is-active {
      background: $light-250;

      .tag {
        background: $light-300;
      }
    }
  }

  &.is-light-100 {
    .tag {
      background: $light-250;
    }

    .icon {
      color: $orange-400;
    }

    &:hover,
    &.is-active {
      background: $light-250;

      .tag {
        background: $light-300;
      }
    }
  }

  &.is-filter-tab {
    font-size: $size-24;
    font-weight: $weight-normal;
    justify-content: space-between;
    padding-left: $space-24;
    height: auto;
    min-height: 48px;
    text-align: left;
    white-space: wrap;
    line-height: 1.2;

    .icon {
      display: none;
      font-size: $size-16;
    }

    &:hover {
      background: $light-250;
    }

    &.is-active {
      background: $light-250;
      font-weight: $weight-medium;

      .icon {
        color: $primary;
        display: inline-flex;
      }
    }
  }

  &.is-secondary-icon {
    border: 3px solid $light-400;
    padding: 0;
    border-color: currentColor;

    &:not([disabled]):hover {
      color: $pink-400;
    }
  }

  &.is-switch-view {
    color: $light-400;
    padding: $space-8;
    border-radius: $radius-8;
    border: 0;

    &:hover {
      color: $black;
      background: $light-250;
    }

    &.is-active {
      color: $primary;
      background: $light-250;
    }
  }

  &.is-card-square {
    aspect-ratio: 1;
    border-radius: $radius-16;
    display: flex;
    height: auto;
    width: 100%;
  }

  &.is-radio-icon {
    height: auto;
    border: 1px solid $border;
    padding: $space-16;
    text-align: left;
    justify-content: flex-start;
    gap: 24px;
    white-space: wrap;
    border-radius: $radius-24;

    .icon {
      background: $light-200;
      border-radius: $radius-8;
      color: $pink-400;
      padding: 16px;
      height: 80px;
      width: 80px;
    }

    &.is-active {
      background: $white;
      border-width: 0;
      border-bottom: 4px solid $orange-400;
      padding: $space-16;

      .icon {
        background: $light-100;
        color: $orange-400;
      }
    }
  }

  &.input {
    height: $control-height;
    font-weight: normal;

    .icon {
      font-size: $icon-32;
    }
  }

  &.is-fullwidth-mobile {
    @include mobile {
      width: 100%;
    }
  }
}

.buttons {
  gap: $space-16;
  flex-wrap: wrap;

  &.is-tight {
    gap: $space-8;
  }

  &.is-loose {
    gap: $space-24;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .button {
    margin-bottom: 0;

    &:not(:last-child):not(.is-fullwidth) {
      margin-right: 0;
    }
  }

  &.is-centered:not(.has-addons) .button:not(.is-fullwidth) {
    margin-left: 0;
    margin-right: 0;
  }
}

#js-tothetop {
  margin-bottom: -24px;
  position: relative;
  z-index: 1;
}
