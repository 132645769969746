.table {
  &.is-borderless {
    border-bottom: 1px solid $border;

    td {
      padding: 0.25rem 0.5rem;
      vertical-align: middle;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }

      .icon-text {
        display: flex;
      }
    }

    tbody > tr > td {
      border-bottom: 0;
    }
  }
}
