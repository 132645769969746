// bulma columns rework with custom spacing values for columnGap

.columns {
  --columnGap: calc(#{$space-16} / 2);
  margin: calc(-1 * var(--columnGap));

  &:last-child {
    margin-bottom: calc(-1 * var(--columnGap));
  }

  &:not(:last-child) {
    margin-bottom: calc(-1 * var(--columnGap) + $space-16);
  }

  &.has-fullheight-box > .column > .box {
    height: 100%;
  }

  > .column {
    padding: var(--columnGap);

    @include mobile {
      &.is-order-1-mobile {
        order: 1;
      }
    }
  }

  @each $name, $value in $spacing-values {
    &.is-#{$name} {
      --columnGap: calc(#{$value} / 2);
    }

    @include mobile {
      &.is-#{$name}-mobile {
        --columnGap: calc(#{$value} / 2);
      }
    }

    @include tablet {
      &.is-#{$name}-tablet {
        --columnGap: calc(#{$value} / 2);
      }
    }

    @include tablet-only {
      &.is-#{$name}-tablet-only {
        --columnGap: calc(#{$value} / 2);
      }
    }

    @include touch {
      &.is-#{$name}-touch {
        --columnGap: calc(#{$value} / 2);
      }
    }

    @include desktop {
      &.is-#{$name}-desktop {
        --columnGap: calc(#{$value} / 2);
      }
    }

    @include desktop-only {
      &.is-#{$name}-desktop-only {
        --columnGap: calc(#{$value} / 2);
      }
    }

    @include widescreen {
      &.is-#{$name}-widescreen {
        --columnGap: calc(#{$value} / 2);
      }
    }

    @include widescreen-only {
      &.is-#{$name}-widescreen-only {
        --columnGap: calc(#{$value} / 2);
      }
    }

    @include fullhd {
      &.is-#{$name}-fullhd {
        --columnGap: calc(#{$value} / 2);
      }
    }
  }
}
