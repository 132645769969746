$white: #ffffff;
$black: $dark-700;

$primary: $orange-400;
$secondary: $light-250;
$background: $light-100;
$text: $black;
$text-strong: $black;

$link: $black;
$link-invert: $white;
$link-focus: $black;
$link-active: $black;

$colors: (
  'white': (
    $white,
    $black,
  ),
  'black': (
    $black,
    $light-100,
  ),
  'primary': (
    $primary,
    $white,
  ),
  'secondary': (
    transparent,
    $black,
  ),
  'green-400': (
    $green-400,
    $white,
  ),
  'green-700': (
    $green-700,
    $white,
  ),
  'orange-400': (
    $orange-400,
    $white,
  ),
  'orange-700': (
    $orange-700,
    $white,
  ),
  'pink-100': (
    $pink-100,
    $black,
  ),
  'pink-400': (
    $pink-400,
    $white,
  ),
  'pink-700': (
    $pink-700,
    $white,
  ),
  'yellow-400': (
    $yellow-400,
    $black,
  ),
  'yellow-600': (
    $yellow-600,
    $black,
  ),
  'dark-600': (
    $dark-600,
    $white,
  ),
  'dark-700': (
    $dark-700,
    $light-250,
  ),
  'dark-800': (
    $dark-800,
    $white,
  ),
  'light-100': (
    $light-100,
    $black,
  ),
  'light-200': (
    $light-200,
    $black,
  ),
  'light-250': (
    $light-250,
    $black,
  ),
  'light-300': (
    $light-300,
    $black,
  ),
  'light-400': (
    $light-400,
    $black,
  ),
);

$tag-colors: $colors;

$notification-colors: (
  'info': (
    $light-100,
    $black,
  ),
  'success': (
    $green-100,
    $green-700,
  ),
  'warning': (
    $yellow-400,
    $black,
  ),
  'danger': (
    $pink-100,
    $pink-700,
  ),
  'error': (
    $pink-100,
    $pink-700,
  ),
);

$border: $light-300;
// $border-hover: $black;
// $block-spacing: 20px;
// $radius-small: $radius-1;
// $radius: $radius-2;
// $radius-large: $radius-2;
