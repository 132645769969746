@charset "utf-8";
@use 'sass:list';

@import './primitives.scss';
@import './colors.scss';

$tablet: 768px;
$desktop: 1024px;
$widescreen: 1366px;
$fullhd: 1920px;

$control-height: clamp(2.5rem, 2.4rem + 0.5vw, 3rem); // 40-48px
// $control-radius: $radius-24;
$control-radius: 1.5rem;
$control-padding-horizontal: $space-24;
$control-padding-vertical: $space-16;
$control-line-height: inherit;

@import '~bulma/sass/utilities/initial-variables.sass';
@import './typo.scss';

@import '~bulma/sass/utilities/_all.sass';

$family-primary: 'DM Sans', BlinkMacSystemFont, -apple-system, sans-serif;
$body-color: $black;
$body-background-color: $light-100;
$body-size: 16px;
$body-line-height: 1.4;
$body-weight: $weight-normal;
$hr-height: 2px;
$hr-margin: 0;
$hr-background-color: $border;
@import '~bulma/sass/base/_all.sass';

$breadcrumb-item-color: $light-400;
$breadcrumb-item-active-color: $primary;
@import '~bulma/sass/components/breadcrumb.sass';

//
// $navbar-height: 2rem;
// $navbar-item-hover-background-color: transparent;
$navbar-background-color: transparent;
$navbar-item-img-max-height: 2rem;
$navbar-breakpoint: 0;
// $navbar-burger-color: $white;
// $navbar-breakpoint: $tablet;
@import '~bulma/sass/components/navbar.sass';

//
// $card-shadow: none;
// $card-content-padding: 1rem 1.25rem 1.25rem;
@import '~bulma/sass/components/card.sass';

//
$dropdown-content-background-color: $white;
$dropdown-item-hover-background-color: $light-100;
$dropdown-content-offset: $space-8;
$dropdown-menu-min-width: 150px;
$dropdown-content-shadow: none;
$dropdown-content-padding-top: 0;
$dropdown-content-padding-bottom: 0;
$dropdown-item-active-background-color: $light-250;
$dropdown-item-active-color: $black;
@import '~bulma/sass/components/dropdown.sass';

//
// $modal-content-width: 640px;
// $modal-content-margin-mobile: 0;
// $modal-content-spacing-mobile: 0px;
// $modal-content-spacing-tablet: 0px;
@import '~bulma/sass/components/modal.sass';

//
@import '~bulma/sass/components/pagination.sass';

//
$tabs-border-bottom-color: $light-300;
$tabs-border-bottom-width: 2px;
$tabs-link-hover-border-bottom-color: $light-400;
$tabs-link-active-border-bottom-color: $primary;
@import '~bulma/sass/components/tabs.sass';

//
// @import "~bulma/sass/layout/_all.sass";

//
$button-background-color: transparent;
$button-border-color: transparent;
$button-hover-border-color: transparent;
$button-focus-border-color: transparent;
$button-active-border-color: transparent;
@import '~bulma/sass/elements/button.sass';
@import '~bulma/sass/elements/content.sass';
@import '~bulma/sass/elements/icon.sass';

$notification-padding-ltr: $space-16;
@import '~bulma/sass/elements/notification.sass';

//
// $table-cell-padding: $space-16 $space-32;
// $table-cell-border: 1px solid $black-10;
// $table-striped-row-even-background-color: $grey-light;
// $table-row-hover-background-color: $accent;
// $table-striped-row-even-hover-background-color: $accent;
@import '~bulma/sass/elements/table.sass';

//

$input-radius: $radius-8;
$input-shadow: 0px 1px 2px 0px #97979766;
$input-placeholder-color: $light-400;
$input-hover-border-color: $light-400;
$input-focus-border-color: $dark-600;
$input-focus-box-shadow-size: 0px 2px 4px 0px;
$input-focus-box-shadow-color: #97979766;
$textarea-padding: $space-16;
@import '~bulma/sass/form/_all.sass';

// columns are custom
$column-gap: 0px;
$variable-columns: false;
@import '~bulma/sass/grid/columns.sass';

//
$spacing-values: (
  '0': 0,
  '4': $space-4,
  '8': $space-8,
  '16': $space-16,
  '24': $space-24,
  '32': $space-32,
  '40': $space-40,
  '48': $space-48,
  '56': $space-56,
  '64': $space-64,
  '72': $space-72,
  '80': $space-80,
  '96': $space-96,
  '104': $space-104,
  '112': $space-112,
  '120': $space-120,
  '152': $space-152,
  '160': $space-160,
  'auto': auto,
);

@import '~bulma/sass/helpers/_all.sass';
