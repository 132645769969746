.swiper {
  --swiper-pagination-color: #{$white};
  // --swiper-pagination-bullet-inactive-color: #{$white};
  // --swiper-pagination-bottom: #{$fixed-space-9};
  overflow: visible;
  position: relative;

  .swiper-wrapper {
    .swiper-slide {
      box-sizing: border-box;
    }
  }

  & > header {
    @include tablet {
      position: absolute;
      top: calc(-1 * (#{$space-56} + #{$control-height}));
      right: 0;
    }
  }

  .box {
    &.is-champion {
      width: 284px;

      @include tablet {
        width: 340px;
      }
    }
  }

  &.is-auto {
    .swiper-slide {
      width: auto;
      height: auto;

      > .box {
        height: 100%;
      }
    }
  }

  &.is-featured {
    margin: 0 8px;
    overflow: visible;

    .swiper-slide {
      position: relative;
      display: flex;
      align-items: flex-end;
      padding: $space-80;
      width: calc(100% - 24px);

      @include tablet {
        width: calc(100% - 160px);
      }

      .slide-content {
        opacity: 0;
        transition: opacity 0.45s, transform 0.75s;
        transition-delay: 0.65s;
        transform: translateX(64px);
      }

      .image {
        transition: filter 0.5s;
        transition-delay: 0.45s;
        border-radius: $space-56;
        overflow: hidden;

        img {
          width: 140%;
          height: 100%;
        }
      }

      &.swiper-slide-active {
        .image {
          filter: brightness(0.45);
        }

        .slide-content {
          opacity: 1;
          transform: translateX(0px);
        }
      }
    }
  }

  &.is-races {
    .swiper-slide {
      cursor: pointer;
      height: auto;
      width: 100%;
      max-width: 96%;
      opacity: 0.5;

      @include tablet {
        max-width: 520px;
      }

      .is-invisible-inactive {
        opacity: 0;
        pointer-events: none;
        transition: opacity 0.25s 0s;
      }

      .is-grey-inactive {
        color: $light-400;
      }

      &:not(.swiper-slide-active) {
        a {
          pointer-events: none;
        }

        &:hover {
          opacity: 0.75;

          h3 {
            color: $dark-700;
          }
        }
      }

      &.swiper-slide-active {
        cursor: default;
        opacity: 1;

        a {
          pointer-events: all;
        }

        .box {
          background: $white;
          border-left: 2px solid $orange-400;

          h3 {
            color: $dark-700;

            &:hover {
              color: $primary;
            }
          }

          .is-grey-inactive {
            color: $pink-400;
          }
        }

        .is-invisible-inactive {
          opacity: 1;
          pointer-events: auto;
          transition: opacity 0.35s 0.35s;
        }
      }
    }
  }

  &.is-quotes {
    overflow: hidden;
    // max-width: 720px;

    & > header {
      position: static;
      margin-top: $space-40;
      display: flex;
      justify-content: center;
    }

    &:before {
      @extend %overlay;

      content: '';
      pointer-events: none;
      z-index: 9;

      background: linear-gradient(
        90deg,
        $light-100 5%,
        rgba(244, 243, 239, 0) calc(50% - 720px / 2),
        rgba(244, 243, 239, 0) calc(50% + 720px / 2),
        $light-100 95%
      );
    }

    .swiper-slide {
      transition: transform 0.3s;
      position: relative;
      width: 80%;

      > .box {
        background: $white;
      }

      @include desktop {
        width: 720px;

        &:not(.swiper-slide-active) {
          > .box {
            background: transparent;
          }
        }
      }
    }
  }

  .box {
    &.is-project-grid {
      width: 288px;
    }
  }
}
