$section-sides: clamp(1rem, 0.2rem + 4vw, 5rem);
$section-top: $space-48;
$section-bottom: $space-112;

section {
  &.section {
    padding: $section-top $section-sides $section-bottom;
  }

  &.is-narrow {
    padding: $space-64 $section-sides $space-64;
  }

  &.is-full-mobile {
    @include mobile {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.is-separated {
    border: 1px solid $border;
    border-width: 1px 0;
  }

  &.is-program {
    h4 {
      margin: $space-32 0 $space-16;
    }

    .columns.is-grid {
      margin: -1px 0;
      border: 1px solid $border;
      border-width: 1px 0;

      > .column {
        &.is-hour {
          border-right: 1px solid $border;
          width: 160px;
        }
      }
    }
  }

  &.is-media-gallery {
    margin: -4px;
  }

  &.avatar-boxes {
    position: relative;
    overflow: hidden;
    border-radius: $radius-16;
    line-height: 0;

    &.has-more {
      padding-right: 32px;
    }

    .image {
      display: inline-block;

      &:not(:first-child) {
        margin-left: -52px;
      }
    }

    .is-card-square {
      position: absolute;
      width: 80px;
      height: 80px;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }
}
