nav {
  &.navbar {
    padding: $space-32;

    .navbar-brand {
      .logo {
        display: flex;

        svg,
        img {
          width: auto;
          margin-right: $space-16;
        }
      }
    }

    .navbar-menu {
      align-items: center;
      .container > & {
        margin-right: 0;
      }
    }
  }
}

.tabs {
  font-size: $size-18;

  ul {
    li {
      a {
        padding: $space-16 $space-24;
      }

      &.is-active {
        background: $white;
        border-radius: $radius-16 $radius-16 0 0;
        font-weight: $weight-semibold;
      }
    }
  }
}
