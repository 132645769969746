.dropdown {
  &.is-header-nav {
    width: 100%;

    .dropdown-trigger {
      width: 100%;

      .button,
      .input {
        justify-content: space-between;
      }
    }

    .dropdown-menu {
      width: 100%;
      .dropdown-content {
        border: 1px solid $border;
        border-radius: $radius-8;
        overflow: auto;

        .dropdown-item {
          font-size: $size-18;
          font-weight: $weight-semibold;
          padding: $space-16;
        }
      }
    }
  }
}
