@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');
@import '~bulma/sass/utilities/mixins.sass';

html {
  font-optical-sizing: auto;
  font-feature-settings: 'liga' 1, 'calt' 1, 'ss07' 1, 'ss08' 1;

  &:has(> body.p-overflow-hidden) {
    overflow: hidden;
  }
}

$size-80: clamp(2rem, 1.4rem + 3vw, 5rem); // 32px - 80px
$size-56: clamp(1.5rem, 1.1rem + 2vw, 3.5rem); // 24px - 56px
$size-40: clamp(1.3125rem, 1.075rem + 1.1875vw, 2.5rem); // 21px - 40px
$size-32: clamp(1.125rem, 0.95rem + 0.875vw, 2rem); // 18px - 32px
$size-24: clamp(1rem, 0.9rem + 0.5vw, 1.5rem); // 16px - 24px
$size-18: clamp(0.875rem, 0.825rem + 0.25vw, 1.125rem); // 14px - 18px
$size-16: clamp(0.75rem, 0.7rem + 0.25vw, 1rem); // 12px - 16px

$typo: (
  'size-80': (
    'font-size': $size-80,
    'line-height': 1.1,
  ),
  'size-56': (
    'font-size': $size-56,
    'line-height': 1.1428571429,
  ),
  'size-40': (
    'font-size': $size-40,
    'line-height': 1.2,
  ),
  'size-32': (
    'font-size': $size-32,
    'line-height': 1.25,
  ),
  'size-24': (
    'font-size': $size-24,
    // 'line-height': 1.1666666667, // ?
    'line-height': 1.3333333333,
  ),
  'size-24-tight': (
    'font-size': $size-24,
    'line-height': 1.1666666667,
  ),
  'size-18': (
    'font-size': $size-18,
    'line-height': 1.3333333333,
  ),
  'size-16': (
    'font-size': $size-16,
    'line-height': 1.25,
  ),
);

@each $name, $type in $typo {
  .is-#{$name} {
    @each $key, $value in $type {
      #{$key}: $value !important;
    }
  }
}

.text-overflow-ellipsis {
  max-width: 100%;
  overflow: hidden;
  // white-space: nowrap;
  text-overflow: ellipsis;
}

.text-no-wrap {
  white-space: nowrap;
}

p.is-limited-overlay {
  position: relative;
  overflow: hidden;
  max-height: 120px;
  z-index: 0;

  &:after {
    @extend %overlay;
    content: '';
    z-index: 1;

    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      $light-100 100%
    );
  }

  &.has-white-overlay:after {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, $white 100%);
  }
}

.is-underlined-hover:hover {
  text-decoration: underline;
}
