$radius-values: (
  '0': 0,
  '8': $radius-8,
  '16': $radius-16,
  '24': $radius-24,
  '32': $radius-32,
  '40': $radius-40,
  '56': $radius-56,
);

@each $key, $value in $radius-values {
  .has-radius-#{$key} {
    border-radius: $value !important;
  }
}

.has-radius-bottom-56 {
  border-bottom-left-radius: $radius-56;
  border-bottom-right-radius: $radius-56;
  margin-bottom: calc(-1 * $space-56);
}
