@import './variables.scss';

@import './animations.scss';
@import './forms.scss';
@import './helpers.scss';
@import './layout.scss';
@import './mixins.scss';
@import './primevue.scss';
@import './section.scss';

@import './bulma/columns.scss';

@import './elements/button.scss';
@import './elements/box.scss';
@import './elements/content.scss';
@import './elements/icon.scss';
@import './elements/image.scss';
@import './elements/table.scss';
@import './elements/tag.scss';

@import './components/accordion.scss';
@import './components/carousel.scss';
@import './components/dropdown.scss';
@import './components/modal.scss';
@import './components/navbar.scss';

@import 'glightbox/dist/css/glightbox.css';
