// COLORS

/* color */
$green-100: #d8fee7;
$green-400: #138994;
$green-700: #126e77;
$orange-400: #f15d25;
$orange-700: #de3e01;
$pink-100: #f9dfe2;
$pink-400: #e41771;
$pink-700: #aa235e;
$yellow-400: #fbe74e;
$yellow-600: #e8cf0a;
$dark-600: #585758;
$dark-700: #424143;
$dark-800: #4d515b;
$light-100: #f4f3ef;
$light-150: #f8f8f5;
$light-200: #ebebe1;
$light-250: #e7e8db;
$light-300: #cbccc4;
$light-400: #8c8c8c;
// $light-400: #979797;
// $light-450: #8c8c8c;
$white: #ffffff;

// --------------------------------------------- SPACING

// SPACING
$space-4: 0.25rem;
$space-8: clamp(0.25rem, 0.2rem + 0.25vw, 0.5rem);
$space-16: clamp(0.5rem, 0.4rem + 0.5vw, 1rem);
$space-24: clamp(0.75rem, 0.6rem + 0.75vw, 1.5rem);
$space-32: clamp(0.75rem, 0.5rem + 1.25vw, 2rem);
$space-40: clamp(0.75rem, 0.4rem + 1.75vw, 2.5rem);
$space-48: clamp(1rem, 0.6rem + 2vw, 3rem);
$space-56: clamp(1rem, 0.5rem + 2.5vw, 3.5rem);
$space-64: clamp(1rem, 0.4rem + 3vw, 4rem);
$space-72: clamp(1.5rem, 0.9rem + 3vw, 4.5rem);
$space-80: clamp(1.5rem, 0.8rem + 3.5vw, 5rem);
$space-96: clamp(2rem, 1.2rem + 4vw, 6rem);
$space-104: clamp(2rem, 1.1rem + 4.5vw, 6.5rem);
$space-112: clamp(2rem, 1rem + 5vw, 7rem);
$space-120: clamp(2.25rem, 1.2rem + 5.25vw, 7.5rem);
$space-128: clamp(2.25rem, 1.1rem + 5.75vw, 8rem);
$space-136: clamp(2.5rem, 1.3rem + 6vw, 8.5rem);
$space-144: clamp(2.5rem, 1.2rem + 6.5vw, 9rem);
$space-152: clamp(3rem, 1.7rem + 6.5vw, 9.5rem);
$space-160: clamp(3rem, 1.6rem + 7vw, 10rem);

// RADIUS
$radius-8: 0.5rem;
// $radius-8: clamp(0.25rem, 0.2rem + 0.25vw, 0.5rem);
$radius-16: clamp(0.5rem, 0.4rem + 0.5vw, 1rem);
$radius-24: clamp(1rem, 0.3rem + 1vw, 1.5rem);
$radius-32: clamp(1rem, 0.8rem + 1vw, 2rem);
$radius-40: clamp(1rem, 0.7rem + 1.5vw, 2.5rem);
$radius-56: clamp(1rem, 0.5rem + 2.5vw, 3.5rem);

// ICON
$icon-16: clamp(0.75rem, 0.7rem + 0.25vw, 1rem);
$icon-21: clamp(0.875rem, 0.7875rem + 0.4375vw, 1.3125rem);
$icon-24: clamp(0.875rem, 0.75rem + 0.625vw, 1.5rem);
$icon-32: clamp(1rem, 0.8rem + 1vw, 2rem);
$icon-40: clamp(1.5rem, 1.3rem + 1vw, 2.5rem);
$icon-72: clamp(1.5rem, 0.9rem + 3vw, 4.5rem);
$icon-80: clamp(2rem, 1.4rem + 3vw, 5rem);

// OLD PRIMITIVES

// SPACING
// $space-4: 0.25rem; // 4px
// $space-8: 0.5rem; // 8px
// $space-16: 1rem; // 16px
// $space-24: clamp(0.75rem, 0.6rem + 0.75vw, 1.5rem); // 24-12px
// $space-32: clamp(1rem, 0.8rem + 1vw, 2rem); // 32-16px
// $space-40: clamp(1.5rem, 1.3rem + 1vw, 2.5rem); // 40-24px
// $space-48: clamp(2rem, 1.8rem + 1vw, 3rem); // 48-32px
// $space-56: clamp(2rem, 1.7rem + 1.5vw, 3.5rem); // 56-32px
// $space-64: clamp(2.5rem, 2.2rem + 1.5vw, 4rem); // 64-40px
// $space-72: clamp(2.5rem, 2.1rem + 2vw, 4.5rem); // 72-40px
// $space-80: clamp(3.5rem, 3.2rem + 1.5vw, 5rem); // 80-56px
// $space-96: clamp(3.5rem, 3rem + 2.5vw, 6rem); // 96-56px
// $space-104: clamp(4rem, 3.5rem + 2.5vw, 6.5rem); // 104-64px
// $space-112: clamp(4rem, 3.4rem + 3vw, 7rem); // 112-64px
// $space-120: clamp(4.5rem, 3.9rem + 3vw, 7.5rem); // 120-72px
// $space-128: clamp(4.5rem, 3.8rem + 3.5vw, 8rem); // 128-72px
// $space-136: clamp(5rem, 4.3rem + 3.5vw, 8.5rem); // 136-80px
// $space-144: clamp(5rem, 4.2rem + 4vw, 9rem); // 144-80px
// $space-152: clamp(5.5rem, 4.7rem + 4vw, 9.5rem); // 152-88px
// $space-160: clamp(5.5rem, 4.6rem + 4.5vw, 10rem); // 160-88px

// RADIUS
// $radius-8: 0.5rem; // 8px
// $radius-16: 1rem; // 16px
// $radius-24: 1.5rem; // 24px
// $radius-32: clamp(1rem, 0.8rem + 1vw, 2rem); // 32-16px
// $radius-40: clamp(1rem, 0.7rem + 1.5vw, 2.5rem); // 40-16px
// $radius-56: clamp(1.5rem, 1.1rem + 2vw, 3.5rem); // 56-24px

// ICON
// $icon-16: 1rem; // 16px
// $icon-24: 1.5rem; // 24px
// $icon-32: clamp(1rem, 0.8rem + 1vw, 2rem); // 32px
// $icon-40: 2.5rem; // 40px
// $icon-48: 3rem; // 48px
// $icon-72: 4.5rem; // 72px
// $icon-88: 5.5rem; // 88px
// $icon-104: clamp(4rem, 3.5rem + 2.5vw, 6.5rem); // 104-64px
// $icon-112: clamp(4rem, 3.4rem + 3vw, 7rem); // 112-64px
