.block-accordion {
  .accordion-container {
    border: 1px solid $border;
    border-radius: $radius-24;
    overflow: hidden;

    & + .accordion-container {
      margin-top: $space-16;
    }

    &:has(.accordion-trigger.is-active) {
      background: $light-200;
    }
  }

  .accordion-trigger {
    white-space: normal;
    text-align: left;
    height: auto;
    min-height: $space-56;
    padding: $space-16 $space-24;
    box-shadow: none !important;

    .icon {
      transform: rotate(90deg);
    }

    &.is-active {
      box-shadow: none;
      outline: none;
      background: transparent;

      .icon {
        transform: rotate(-90deg);
      }

      & + .accordion-body {
        display: block;
      }
    }
  }

  .accordion-body {
    display: none;
    overflow: hidden;
    padding: 0 $space-24 $space-24;
  }
}
