.box {
  border-radius: $radius-16;

  &.has-border {
    border: 1px solid $border;

    &-primary {
      border: 1px solid $primary;
    }

    &-top {
      border: 0;
      border-radius: 0;
      border-top: 1px solid $border;
    }

    &-bottom {
      border: 0;
      border-radius: 0;
      border-bottom: 1px solid $border;

      &.is-mobile {
        border-bottom: 0;
      }
    }
  }

  &.is-hoverable {
    transition: box-shadow 0.25s, background 0.25s;

    &:hover {
      box-shadow: 4px 16px 24px 0px rgba(151, 151, 151, 0.6);
      background-color: $light-150 !important;

      .is-link-title {
        color: $primary;
      }
    }
  }

  &.is-centered {
    display: flex;
    justify-content: center;
    align-items: center;

    .is-size-40 {
      line-height: 1 !important;
    }
  }

  &.is-project-grid {
    overflow: hidden;
    position: relative;
    z-index: 0;
    display: flex;
    flex-direction: column;
    transition: box-shadow 0.25s;

    &.is-small {
      color: $white;
      padding: $space-32;

      a {
        color: $white;
      }
    }

    &.is-large,
    &.is-medium {
      background: $white;
      border: 1px solid $border;
      padding: $space-32 $space-40 $space-56;
    }

    &:hover {
      .is-limited-overlay {
        color: $black;

        &:after {
          background: linear-gradient(
            180deg,
            rgba(255, 255, 255, 0) 0%,
            $light-150 100%
          );
        }
      }
    }

    .is-limited-overlay {
      color: $light-400;
    }
  }

  &.is-race {
    border-left: 1px solid $border;
    display: flex;
    flex-direction: column;
    height: 100%;

    h3 {
      color: $light-400;
    }
  }

  &.is-race-horizontal {
    background: $white;

    &:hover {
      box-shadow: none;
    }
  }

  &.is-champion {
    display: flex;

    @include desktop {
      min-height: 360px;
    }

    a {
      color: $white;
    }

    .image {
      bottom: 120px;

      @include desktop {
        bottom: 0;
      }

      > p {
        position: absolute;
        left: 0;
        width: 100%;
        bottom: 0;
      }
    }

    .box-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: $space-32;
      margin-top: 180px;
      min-height: 180px;
      flex: 1;

      @include desktop {
        margin-top: 0;
        min-height: none;
        transition: opacity 0.25s;
        height: 100%;
        opacity: 0;
      }
    }

    &:hover {
      .box-content {
        opacity: 1;
      }
    }
  }

  &.is-quote {
    transition: background-color 0.25s;
    padding: $space-56 $space-40 $space-56 $space-96;
    display: flex;
    flex-direction: column;

    .icon {
      position: absolute;
      left: -3rem;
      top: -1rem;
    }
  }

  &.is-news {
    border-top: 1px solid $border;
    transition: background-color 0.25s;

    &.is-big {
      @include desktop {
        .is-size-18 {
          margin-top: $space-40;
        }

        a.button {
          margin-bottom: $space-40;
        }
      }
    }

    &:hover {
      background: $white;

      h3 {
        color: $primary;
      }
    }
  }

  &.is-sticky-footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: $radius-16 $radius-16 0 0;
    z-index: 9;
    box-shadow: -1px -1px 7px 0px rgba(151, 151, 151, 0.41);

    @include tablet {
      margin: 0 auto;
      position: sticky;
      bottom: $size-32;
      box-shadow: 0px 2px 4px 0px #97979766;
      border-radius: $radius-16;
      border: 1px solid $border;
    }
  }
}

a.box {
  display: block;
}
