@use 'sass:list';
@use 'sass:map';

.tag {
  font-size: $size-18;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 $space-8;
  border-radius: $radius-24;
  height: clamp(1rem, 0.9rem + 0.5vw, 1.5rem); // 16-24px
  min-width: clamp(1rem, 0.9rem + 0.5vw, 1.5rem); // 16-24px

  .icon {
    &:first-child {
      &:last-child {
        margin: 0;
      }

      &:not(:last-child) {
        margin-left: 0;
        margin-right: $space-8;
      }
    }
  }

  &.is-square {
    padding: $space-4;
  }

  &.is-small {
    padding: 0 $space-8;
  }

  &.is-medium {
    height: $size-40;
    min-width: $size-40;
    padding: $space-8 $space-16;
    font-weight: $weight-semibold;
    font-size: $size-18;
  }

  @each $name, $pair in $tag-colors {
    $color: list.nth($pair, 1);
    $color-invert: list.nth($pair, 2);

    &.is-#{$name} {
      background-color: $color;
      color: $color-invert;
    }
  }
}

.tags {
  display: flex;
  align-items: center;
  gap: $space-8;
  flex-wrap: wrap;
}
