html {
  height: 100%;
}

body {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  > main {
    flex: 1;
    font-size: $size-16;
  }
}

.container {
  max-width: $fullhd;
  min-width: 300px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  // &.is-wide { max-width: 1920px; }
}

#loader {
  @extend %overlay;
  position: fixed;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.35);
  display: flex;
  align-items: center;
  justify-content: center;

  svg circle {
    stroke-width: 4px;
    stroke: $white !important;
  }
}

// MISC

.is-limited {
  &-360 {
    max-width: 360px;
  }

  &-560 {
    max-width: 560px;
  }

  &-640 {
    max-width: 640px;
  }

  &-720 {
    max-width: 720px;
  }

  &-840 {
    max-width: 840px;
  }

  &-960 {
    max-width: 960px;
  }

  &-1080 {
    max-width: 1080px;
  }

  &-1160 {
    max-width: 1160px;
  }

  &-1312 {
    max-width: 1312px;
  }
}

.is-scroll-heading {
  // scroll-margin-top: calc(80px + $space-40);
}

.is-sticky {
  position: sticky;
  top: $size-24;
}
