.content {
  a {
    text-decoration: underline;
  }
}

article.page-article {
  .article-block {
    margin-top: $space-24;
    margin-bottom: $space-24;

    &:first-child {
      margin-top: 0 !important;
    }

    &:last-child {
      margin-bottom: 0 !important;
    }

    &.block-heading_block {
      margin-top: $space-48;
    }

    &.block-block_quote {
      margin-top: $space-48;
      margin-bottom: $space-48;

      .box {
        background: $white;
      }
    }

    &.block-emphasized_text {
      margin-top: $space-32;
      margin-bottom: $space-32;
    }

    &.block-accordion {
      margin-top: $space-48;
      margin-bottom: $space-48;
    }

    &.block-image_block {
      margin-top: $space-48;
      margin-bottom: $space-48;
    }
  }
}

.breadcrumb {
  a {
    display: block;
  }
}

table {
  td,
  th {
    // vertical-align: middle;
  }
}
