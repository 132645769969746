.image {
  img {
    display: block;
    width: 100%;

    &.is-cover {
      object-fit: cover;
    }

    &.is-contain {
      object-fit: contain;
    }
  }

  &.is-overlay {
    z-index: -1;

    img {
      height: 100%;
    }
  }

  &.has-gradient-overlay {
    &:after {
      @extend %overlay;
      content: '';
      background: linear-gradient(180deg, rgba(66, 65, 67, 0) 0%, #000000 100%);
      z-index: 1;
    }
  }

  &.is-gauge {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 120px;
    z-index: 0;

    &.is-small {
      width: 72px;
      height: 72px;

      @include mobile {
        width: 56px;
        height: 56px;
      }
    }

    &.is-tiny {
      width: 56px;
      height: 56px;
    }

    svg {
      position: absolute;
      width: 100%;
      z-index: -1;
    }
  }

  &.is-avatar img {
    width: clamp(3rem, 2.6rem + 2vw, 5rem);
    height: clamp(3rem, 2.6rem + 2vw, 5rem);
  }

  &.is-logo {
    filter: grayscale(100%);

    img {
      height: 56px;
      width: auto;

      @include tablet {
        height: 64px;
      }
    }
  }

  iframe {
    display: block;
    width: 100%;
    height: 100%;
  }
}

$aspect-ratios: (
  '1by3': calc(1 / 3),
  '1by2': calc(1 / 2),
  '2by3': calc(2 / 3),
  '1by1': 1,
  '5by4': calc(5 / 4),
  '5by3': calc(5 / 3),
  '4by3': calc(4 / 3),
  '3by4': calc(3 / 4),
  '3by2': calc(3 / 2),
  '2by1': 2,
  '3by1': 3,
  '16by9': calc(16 / 9),
  '21by9': calc(21 / 9),
  '9by16': calc(9 / 16),
);

@each $key, $ratio in $aspect-ratios {
  .is-#{$key}:not(.image) {
    aspect-ratio: $ratio;
  }

  .image.is-#{$key} {
    img,
    iframe {
      aspect-ratio: $ratio;
    }
  }
}
